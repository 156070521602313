
export default {
    multicallAddress: {
        1: '0x4427EA4F413F70768e088D2a8eB6aBCB3DC58E61',
        56: '0x7daB5e3c124097184f1A66211C1470a8cC7858fe',
        // 97: '0x5a97FD5Ea9b025f38c08EF5198e6779830c5188E',
        97: '0x672F5Df3F25FB7b63f665aE97a9B28d12Aba6B8F',
        137: '0x1156256625faa48840Aea2Cd6f96418FDB4370fB',
        8453: '0x60B1787898B6a592f5B946e95a74B51Baa0640Dd',
    },
    factoryAddress: {
        1: '0x9a7A6AD539Fb0250feAF98Be3fC8ad346db2Eb41',
        56: '0xb5FF1ef9e3BC550B62364Af8257A2FF012bd153d',
        // 97: '0xeC7e8c6D31A6813a37b30923B602Da2793fd858B',
        97: '0xEF48407D9f0E9a61f42f0B87ab5b014f01E96354',
        137: '0xE1C7759AebCd688e5f23B7C7A96a2dB87582A9Ae',
        8453: '0xA0B045C296a93F1786bdE8F49e79167F99BCBa57',
    },
    contractAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        97: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
    },
    WETHAddress: {
        1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        97: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        8453: '0x4200000000000000000000000000000000000006',
    },
    routerAddress: {
        1: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        97: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
        137: '0xedf6066a2b290C185783862C7F4776A2C8077AD1',
        8453: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
    },
    defaultAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        97: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
    },
}